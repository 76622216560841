@import url(fontawesome-all.min.css);
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300italic,600,600italic");

/*
	Elemental by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

	blockquote:before, blockquote:after, q:before, q:after {
		content: '';
		content: none;
	}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	-webkit-text-size-adjust: none;
}

mark {
	background-color: transparent;
	color: inherit;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input, select, textarea {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
}

/* Basic */

	@-ms-viewport {
		width: device-width;
	}

	body {
		-ms-overflow-style: scrollbar;
	}

	@media screen and (max-width: 480px) {

		html, body {
			min-width: 320px;
		}

	}

	html {
		box-sizing: border-box;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	body {
		background: #fef4b0;
	}

		body.is-preload *, body.is-preload *:before, body.is-preload *:after {
			-moz-animation: none !important;
			-webkit-animation: none !important;
			-ms-animation: none !important;
			animation: none !important;
			-moz-transition: none !important;
			-webkit-transition: none !important;
			-ms-transition: none !important;
			transition: none !important;
		}

/* Type */

	body {
		background-color: #fef4b0;
		color: #6e6e6e;
	}

	body, input, select, textarea {
		font-family: "Source Sans Pro", Helvetica, sans-serif;
		font-size: 14pt;
		font-weight: 300;
		line-height: 1.65;
		letter-spacing: 0.05em;
	}

		@media screen and (max-width: 1680px) {

			body, input, select, textarea {
				font-size: 12pt;
			}

		}

		@media screen and (max-width: 736px) {

			body, input, select, textarea {
				font-size: 11.5pt;
			}

		}

		@media screen and (max-width: 360px) {

			body, input, select, textarea {
				font-size: 10.5pt;
			}

		}

	a {
		-moz-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
		-webkit-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
		-ms-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
		transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
		text-decoration: none;
		border-bottom: dotted 1px;
		color: inherit;
	}

		a:hover {
			border-bottom-color: transparent;
		}

			a:hover strong {
				color: inherit !important;
			}

	strong, b {
		font-weight: 600;
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 2em 0;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: 300;
		line-height: 1.5;
		margin: 0 0 0.7em 0;
	}

		h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
			color: inherit;
			text-decoration: none;
		}

	h1 {
		font-size: 3em;
	}

	h2 {
		font-size: 2em;
	}

	h3 {
		font-size: 1.5em;
	}

	h4 {
		font-size: 1.1em;
	}

	h5 {
		font-size: 0.9em;
	}

	h6 {
		font-size: 0.7em;
	}

	@media screen and (max-width: 1280px) {

		h1 {
			font-size: 2.5em;
		}

	}

	@media screen and (max-width: 736px) {

		h1 {
			font-size: 1.75em;
		}

		h2 {
			font-size: 1.5em;
		}

		h3 {
			font-size: 1.35em;
		}

		h4 {
			font-size: 1em;
		}

		h1 br, h2 br, h3 br, h4 br, h5 br, h6 br {
			display: none;
		}

	}

	@media screen and (max-width: 480px) {

		h2 {
			font-size: 1.13em;
			padding-bottom: 1.5em;
		}

		h3 {
			font-size: 1em;
		}

	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	blockquote {
		border-left: solid 4px;
		font-style: italic;
		margin: 0 0 2em 0;
		padding: 0.5em 0 0.5em 2em;
	}

	code {
		border-radius: 4px;
		border: solid 1px;
		font-family: "Courier New", monospace;
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: "Courier New", monospace;
		font-size: 0.9em;
		margin: 0 0 2em 0;
	}

		pre code {
			display: block;
			line-height: 1.75;
			padding: 1em 1.5em;
			overflow-x: auto;
		}

	hr {
		border: 0;
		border-bottom: solid 1px;
		margin: 2em 0;
	}

		hr.major {
			margin: 3em 0;
		}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

	input, select, textarea {
		color: #5e5e5e;
	}

	a:hover {
		color: #e09476 !important;
	}

	h1, h2 {
		font-weight: bold;
		color: #b01220;
	}

	h3, h4, h5, h6 {
		color: #5e5e5e;
	}


	blockquote {
		border-left-color: #e3e3e3;
	}

	code {
		background: rgba(144, 144, 144, 0.075);
		border-color: #e3e3e3;
	}

	hr {
		border-bottom-color: #e3e3e3;
	}

/* Row */

	.row {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: stretch;
	}

		.row > * {
			box-sizing: border-box;
		}

		.row.gtr-uniform > * > :last-child {
			margin-bottom: 0;
		}

		.row.aln-left {
			justify-content: flex-start;
		}

		.row.aln-center {
			justify-content: center;
		}

		.row.aln-right {
			justify-content: flex-end;
		}

		.row.aln-top {
			align-items: flex-start;
		}

		.row.aln-middle {
			align-items: center;
		}

		.row.aln-bottom {
			align-items: flex-end;
		}

		.row > .imp {
			order: -1;
		}

		.row > .col-1 {
			width: 8.33333%;
		}

		.row > .off-1 {
			margin-left: 8.33333%;
		}

		.row > .col-2 {
			width: 16.66667%;
		}

		.row > .off-2 {
			margin-left: 16.66667%;
		}

		.row > .col-3 {
			width: 25%;
		}

		.row > .off-3 {
			margin-left: 25%;
		}

		.row > .col-4 {
			width: 33.33333%;
		}

		.row > .off-4 {
			margin-left: 33.33333%;
		}

		.row > .col-5 {
			width: 41.66667%;
		}

		.row > .off-5 {
			margin-left: 41.66667%;
		}

		.row > .col-6 {
			width: 50%;
		}

		.row > .off-6 {
			margin-left: 50%;
		}

		.row > .col-7 {
			width: 58.33333%;
		}

		.row > .off-7 {
			margin-left: 58.33333%;
		}

		.row > .col-8 {
			width: 66.66667%;
		}

		.row > .off-8 {
			margin-left: 66.66667%;
		}

		.row > .col-9 {
			width: 75%;
		}

		.row > .off-9 {
			margin-left: 75%;
		}

		.row > .col-10 {
			width: 83.33333%;
		}

		.row > .off-10 {
			margin-left: 83.33333%;
		}

		.row > .col-11 {
			width: 91.66667%;
		}

		.row > .off-11 {
			margin-left: 91.66667%;
		}

		.row > .col-12 {
			width: 100%;
		}

		.row > .off-12 {
			margin-left: 100%;
		}

		.row.gtr-0 {
			margin-top: 0;
			margin-left: 0em;
		}

			.row.gtr-0 > * {
				padding: 0 0 0 0em;
			}

			.row.gtr-0.gtr-uniform {
				margin-top: 0em;
			}

				.row.gtr-0.gtr-uniform > * {
					padding-top: 0em;
				}

		.row.gtr-25 {
			margin-top: 0;
			margin-left: -0.3375em;
		}

			.row.gtr-25 > * {
				padding: 0 0 0 0.3375em;
			}

			.row.gtr-25.gtr-uniform {
				margin-top: -0.3375em;
			}

				.row.gtr-25.gtr-uniform > * {
					padding-top: 0.3375em;
				}

		.row.gtr-50 {
			margin-top: 0;
			margin-left: -0.675em;
		}

			.row.gtr-50 > * {
				padding: 0 0 0 0.675em;
			}

			.row.gtr-50.gtr-uniform {
				margin-top: -0.675em;
			}

				.row.gtr-50.gtr-uniform > * {
					padding-top: 0.675em;
				}

		.row {
			margin-top: 0;
			margin-left: -1.35em;
		}

			.row > * {
				padding: 0 0 0 1.35em;
			}

			.row.gtr-uniform {
				margin-top: -1.35em;
			}

				.row.gtr-uniform > * {
					padding-top: 1.35em;
				}

		.row.gtr-150 {
			margin-top: 0;
			margin-left: -2.025em;
		}

			.row.gtr-150 > * {
				padding: 0 0 0 2.025em;
			}

			.row.gtr-150.gtr-uniform {
				margin-top: -2.025em;
			}

				.row.gtr-150.gtr-uniform > * {
					padding-top: 2.025em;
				}

		.row.gtr-200 {
			margin-top: 0;
			margin-left: -2.7em;
		}

			.row.gtr-200 > * {
				padding: 0 0 0 2.7em;
			}

			.row.gtr-200.gtr-uniform {
				margin-top: -2.7em;
			}

				.row.gtr-200.gtr-uniform > * {
					padding-top: 2.7em;
				}

		@media screen and (max-width: 1680px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-xlarge {
					order: -1;
				}

				.row > .col-1-xlarge {
					width: 8.33333%;
				}

				.row > .off-1-xlarge {
					margin-left: 8.33333%;
				}

				.row > .col-2-xlarge {
					width: 16.66667%;
				}

				.row > .off-2-xlarge {
					margin-left: 16.66667%;
				}

				.row > .col-3-xlarge {
					width: 25%;
				}

				.row > .off-3-xlarge {
					margin-left: 25%;
				}

				.row > .col-4-xlarge {
					width: 33.33333%;
				}

				.row > .off-4-xlarge {
					margin-left: 33.33333%;
				}

				.row > .col-5-xlarge {
					width: 41.66667%;
				}

				.row > .off-5-xlarge {
					margin-left: 41.66667%;
				}

				.row > .col-6-xlarge {
					width: 50%;
				}

				.row > .off-6-xlarge {
					margin-left: 50%;
				}

				.row > .col-7-xlarge {
					width: 58.33333%;
				}

				.row > .off-7-xlarge {
					margin-left: 58.33333%;
				}

				.row > .col-8-xlarge {
					width: 66.66667%;
				}

				.row > .off-8-xlarge {
					margin-left: 66.66667%;
				}

				.row > .col-9-xlarge {
					width: 75%;
				}

				.row > .off-9-xlarge {
					margin-left: 75%;
				}

				.row > .col-10-xlarge {
					width: 83.33333%;
				}

				.row > .off-10-xlarge {
					margin-left: 83.33333%;
				}

				.row > .col-11-xlarge {
					width: 91.66667%;
				}

				.row > .off-11-xlarge {
					margin-left: 91.66667%;
				}

				.row > .col-12-xlarge {
					width: 100%;
				}

				.row > .off-12-xlarge {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.3375em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.3375em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.3375em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.3375em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -0.675em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 0.675em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -0.675em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 0.675em;
						}

				.row {
					margin-top: 0;
					margin-left: -1.35em;
				}

					.row > * {
						padding: 0 0 0 1.35em;
					}

					.row.gtr-uniform {
						margin-top: -1.35em;
					}

						.row.gtr-uniform > * {
							padding-top: 1.35em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -2.025em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 2.025em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -2.025em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 2.025em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -2.7em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 2.7em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -2.7em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 2.7em;
						}

		}

		@media screen and (max-width: 1280px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-large {
					order: -1;
				}

				.row > .col-1-large {
					width: 8.33333%;
				}

				.row > .off-1-large {
					margin-left: 8.33333%;
				}

				.row > .col-2-large {
					width: 16.66667%;
				}

				.row > .off-2-large {
					margin-left: 16.66667%;
				}

				.row > .col-3-large {
					width: 25%;
				}

				.row > .off-3-large {
					margin-left: 25%;
				}

				.row > .col-4-large {
					width: 33.33333%;
				}

				.row > .off-4-large {
					margin-left: 33.33333%;
				}

				.row > .col-5-large {
					width: 41.66667%;
				}

				.row > .off-5-large {
					margin-left: 41.66667%;
				}

				.row > .col-6-large {
					width: 50%;
				}

				.row > .off-6-large {
					margin-left: 50%;
				}

				.row > .col-7-large {
					width: 58.33333%;
				}

				.row > .off-7-large {
					margin-left: 58.33333%;
				}

				.row > .col-8-large {
					width: 66.66667%;
				}

				.row > .off-8-large {
					margin-left: 66.66667%;
				}

				.row > .col-9-large {
					width: 75%;
				}

				.row > .off-9-large {
					margin-left: 75%;
				}

				.row > .col-10-large {
					width: 83.33333%;
				}

				.row > .off-10-large {
					margin-left: 83.33333%;
				}

				.row > .col-11-large {
					width: 91.66667%;
				}

				.row > .off-11-large {
					margin-left: 91.66667%;
				}

				.row > .col-12-large {
					width: 100%;
				}

				.row > .off-12-large {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.3375em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.3375em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.3375em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.3375em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -0.675em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 0.675em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -0.675em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 0.675em;
						}

				.row {
					margin-top: 0;
					margin-left: -1.35em;
				}

					.row > * {
						padding: 0 0 0 1.35em;
					}

					.row.gtr-uniform {
						margin-top: -1.35em;
					}

						.row.gtr-uniform > * {
							padding-top: 1.35em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -2.025em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 2.025em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -2.025em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 2.025em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -2.7em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 2.7em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -2.7em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 2.7em;
						}

		}

		@media screen and (max-width: 980px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-medium {
					order: -1;
				}

				.row > .col-1-medium {
					width: 8.33333%;
				}

				.row > .off-1-medium {
					margin-left: 8.33333%;
				}

				.row > .col-2-medium {
					width: 16.66667%;
				}

				.row > .off-2-medium {
					margin-left: 16.66667%;
				}

				.row > .col-3-medium {
					width: 25%;
				}

				.row > .off-3-medium {
					margin-left: 25%;
				}

				.row > .col-4-medium {
					width: 33.33333%;
				}

				.row > .off-4-medium {
					margin-left: 33.33333%;
				}

				.row > .col-5-medium {
					width: 41.66667%;
				}

				.row > .off-5-medium {
					margin-left: 41.66667%;
				}

				.row > .col-6-medium {
					width: 50%;
				}

				.row > .off-6-medium {
					margin-left: 50%;
				}

				.row > .col-7-medium {
					width: 58.33333%;
				}

				.row > .off-7-medium {
					margin-left: 58.33333%;
				}

				.row > .col-8-medium {
					width: 66.66667%;
				}

				.row > .off-8-medium {
					margin-left: 66.66667%;
				}

				.row > .col-9-medium {
					width: 75%;
				}

				.row > .off-9-medium {
					margin-left: 75%;
				}

				.row > .col-10-medium {
					width: 83.33333%;
				}

				.row > .off-10-medium {
					margin-left: 83.33333%;
				}

				.row > .col-11-medium {
					width: 91.66667%;
				}

				.row > .off-11-medium {
					margin-left: 91.66667%;
				}

				.row > .col-12-medium {
					width: 100%;
				}

				.row > .off-12-medium {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.3375em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.3375em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.3375em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.3375em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -0.675em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 0.675em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -0.675em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 0.675em;
						}

				.row {
					margin-top: 0;
					margin-left: -1.35em;
				}

					.row > * {
						padding: 0 0 0 1.35em;
					}

					.row.gtr-uniform {
						margin-top: -1.35em;
					}

						.row.gtr-uniform > * {
							padding-top: 1.35em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -2.025em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 2.025em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -2.025em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 2.025em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -2.7em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 2.7em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -2.7em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 2.7em;
						}

		}

		@media screen and (max-width: 736px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-small {
					order: -1;
				}

				.row > .col-1-small {
					width: 8.33333%;
				}

				.row > .off-1-small {
					margin-left: 8.33333%;
				}

				.row > .col-2-small {
					width: 16.66667%;
				}

				.row > .off-2-small {
					margin-left: 16.66667%;
				}

				.row > .col-3-small {
					width: 25%;
				}

				.row > .off-3-small {
					margin-left: 25%;
				}

				.row > .col-4-small {
					width: 33.33333%;
				}

				.row > .off-4-small {
					margin-left: 33.33333%;
				}

				.row > .col-5-small {
					width: 41.66667%;
				}

				.row > .off-5-small {
					margin-left: 41.66667%;
				}

				.row > .col-6-small {
					width: 50%;
				}

				.row > .off-6-small {
					margin-left: 50%;
				}

				.row > .col-7-small {
					width: 58.33333%;
				}

				.row > .off-7-small {
					margin-left: 58.33333%;
				}

				.row > .col-8-small {
					width: 66.66667%;
				}

				.row > .off-8-small {
					margin-left: 66.66667%;
				}

				.row > .col-9-small {
					width: 75%;
				}

				.row > .off-9-small {
					margin-left: 75%;
				}

				.row > .col-10-small {
					width: 83.33333%;
				}

				.row > .off-10-small {
					margin-left: 83.33333%;
				}

				.row > .col-11-small {
					width: 91.66667%;
				}

				.row > .off-11-small {
					margin-left: 91.66667%;
				}

				.row > .col-12-small {
					width: 100%;
				}

				.row > .off-12-small {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.3375em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.3375em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.3375em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.3375em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -0.675em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 0.675em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -0.675em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 0.675em;
						}

				.row {
					margin-top: 0;
					margin-left: -1.35em;
				}

					.row > * {
						padding: 0 0 0 1.35em;
					}

					.row.gtr-uniform {
						margin-top: -1.35em;
					}

						.row.gtr-uniform > * {
							padding-top: 1.35em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -2.025em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 2.025em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -2.025em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 2.025em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -2.7em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 2.7em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -2.7em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 2.7em;
						}

		}

		@media screen and (max-width: 480px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-xsmall {
					order: -1;
				}

				.row > .col-1-xsmall {
					width: 8.33333%;
				}

				.row > .off-1-xsmall {
					margin-left: 8.33333%;
				}

				.row > .col-2-xsmall {
					width: 16.66667%;
				}

				.row > .off-2-xsmall {
					margin-left: 16.66667%;
				}

				.row > .col-3-xsmall {
					width: 25%;
				}

				.row > .off-3-xsmall {
					margin-left: 25%;
				}

				.row > .col-4-xsmall {
					width: 33.33333%;
				}

				.row > .off-4-xsmall {
					margin-left: 33.33333%;
				}

				.row > .col-5-xsmall {
					width: 41.66667%;
				}

				.row > .off-5-xsmall {
					margin-left: 41.66667%;
				}

				.row > .col-6-xsmall {
					width: 50%;
				}

				.row > .off-6-xsmall {
					margin-left: 50%;
				}

				.row > .col-7-xsmall {
					width: 58.33333%;
				}

				.row > .off-7-xsmall {
					margin-left: 58.33333%;
				}

				.row > .col-8-xsmall {
					width: 66.66667%;
				}

				.row > .off-8-xsmall {
					margin-left: 66.66667%;
				}

				.row > .col-9-xsmall {
					width: 75%;
				}

				.row > .off-9-xsmall {
					margin-left: 75%;
				}

				.row > .col-10-xsmall {
					width: 83.33333%;
				}

				.row > .off-10-xsmall {
					margin-left: 83.33333%;
				}

				.row > .col-11-xsmall {
					width: 91.66667%;
				}

				.row > .off-11-xsmall {
					margin-left: 91.66667%;
				}

				.row > .col-12-xsmall {
					width: 100%;
				}

				.row > .off-12-xsmall {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.3375em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.3375em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.3375em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.3375em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -0.675em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 0.675em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -0.675em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 0.675em;
						}

				.row {
					margin-top: 0;
					margin-left: -1.35em;
				}

					.row > * {
						padding: 0 0 0 1.35em;
					}

					.row.gtr-uniform {
						margin-top: -1.35em;
					}

						.row.gtr-uniform > * {
							padding-top: 1.35em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -2.025em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 2.025em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -2.025em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 2.025em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -2.7em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 2.7em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -2.7em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 2.7em;
						}

		}

/* Box */

	.box {
		border-radius: 4px;
		border: solid 1px;
		margin-bottom: 2em;
		padding: 1.5em;
	}

		.box > :last-child,
		.box > :last-child > :last-child,
		.box > :last-child > :last-child > :last-child {
			margin-bottom: 0;
		}

		.box.alt {
			border: 0;
			border-radius: 0;
			padding: 0;
		}

	.box {
		border-color: #e3e3e3;
	}

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		-moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		border-radius: 4px;
		border: 0;
		cursor: pointer;
		display: inline-block;
		font-weight: 600;
		height: 3.025em;
		line-height: 3.025em;
		padding: 0 3em;
		text-align: center;
		text-decoration: none;
		white-space: nowrap;
	}

		input[type="submit"].icon:before,
		input[type="reset"].icon:before,
		input[type="button"].icon:before,
		button.icon:before,
		.button.icon:before {
			margin-right: 0.5em;
			margin-left: -0.35em;
		}

		input[type="submit"].fit,
		input[type="reset"].fit,
		input[type="button"].fit,
		button.fit,
		.button.fit {
			width: 100%;
		}

		input[type="submit"].small,
		input[type="reset"].small,
		input[type="button"].small,
		button.small,
		.button.small {
			font-size: 0.8em;
		}

		input[type="submit"].large,
		input[type="reset"].large,
		input[type="button"].large,
		button.large,
		.button.large {
			font-size: 1.35em;
		}

		input[type="submit"].disabled, input[type="submit"]:disabled,
		input[type="reset"].disabled,
		input[type="reset"]:disabled,
		input[type="button"].disabled,
		input[type="button"]:disabled,
		button.disabled,
		button:disabled,
		.button.disabled,
		.button:disabled {
			pointer-events: none;
			opacity: 0.35;
		}

		@media screen and (max-width: 480px) {

			input[type="submit"],
			input[type="reset"],
			input[type="button"],
			button,
			.button {
				padding: 0;
			}

		}

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		background-color: transparent;
		box-shadow: inset 0 0 0 1px #e3e3e3;
		color: #5e5e5e !important;
	}

		input[type="submit"]:hover,
		input[type="reset"]:hover,
		input[type="button"]:hover,
		button:hover,
		.button:hover {
			background-color: rgba(144, 144, 144, 0.075);
			color: #5e5e5e !important;
		}

		input[type="submit"]:active,
		input[type="reset"]:active,
		input[type="button"]:active,
		button:active,
		.button:active {
			background-color: rgba(144, 144, 144, 0.125);
		}

		input[type="submit"].primary,
		input[type="reset"].primary,
		input[type="button"].primary,
		button.primary,
		.button.primary {
			box-shadow: none;
			background-color: #e09476;
			color: #ffffff !important;
		}

			input[type="submit"].primary:hover,
			input[type="reset"].primary:hover,
			input[type="button"].primary:hover,
			button.primary:hover,
			.button.primary:hover {
				background-color: #e5a48b;
			}

			input[type="submit"].primary:active,
			input[type="reset"].primary:active,
			input[type="button"].primary:active,
			button.primary:active,
			.button.primary:active {
				background-color: #db8461;
			}

/* Form */

	form {
		margin: 0 0 2em 0;
	}

	label {
		display: block;
		font-size: 0.9em;
		font-weight: 300;
		margin: 0 0 1em 0;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select,
	textarea {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		border-radius: 4px;
		border: none;
		border: solid 1px;
		color: inherit;
		display: block;
		outline: 0;
		padding: 0 1em;
		text-decoration: none;
		width: 100%;
	}

		input[type="text"]:invalid,
		input[type="password"]:invalid,
		input[type="email"]:invalid,
		select:invalid,
		textarea:invalid {
			box-shadow: none;
		}

	select {
		background-size: 1.25rem;
		background-repeat: no-repeat;
		background-position: calc(100% - 1rem) center;
		height: 2.75em;
		padding-right: 2.75em;
		text-overflow: ellipsis;
	}

		select:focus::-ms-value {
			background-color: transparent;
		}

		select::-ms-expand {
			display: none;
		}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select {
		height: 2.75em;
	}

	textarea {
		padding: 0.75em 1em;
	}

	input[type="checkbox"],
	input[type="radio"] {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		display: block;
		float: left;
		margin-right: -2em;
		opacity: 0;
		width: 1em;
		z-index: -1;
	}

		input[type="checkbox"] + label,
		input[type="radio"] + label {
			text-decoration: none;
			cursor: pointer;
			display: inline-block;
			font-size: 1em;
			font-weight: 300;
			padding-left: 2.4em;
			padding-right: 0.75em;
			position: relative;
		}

			input[type="checkbox"] + label:before,
			input[type="radio"] + label:before {
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				text-transform: none !important;
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
			}

			input[type="checkbox"] + label:before,
			input[type="radio"] + label:before {
				border-radius: 4px;
				border: solid 1px;
				content: '';
				display: inline-block;
				font-size: 0.8em;
				height: 1.65em;
				left: 0;
				line-height: 1.58125em;
				position: absolute;
				text-align: center;
				top: 0;
				width: 1.65em;
			}

		input[type="checkbox"]:checked + label:before,
		input[type="radio"]:checked + label:before {
			content: '\f00c';
		}

	input[type="checkbox"] + label:before {
		border-radius: 4px;
	}

	input[type="radio"] + label:before {
		border-radius: 100%;
	}

	::-webkit-input-placeholder {
		opacity: 1.0;
	}

	:-moz-placeholder {
		opacity: 1.0;
	}

	::-moz-placeholder {
		opacity: 1.0;
	}

	:-ms-input-placeholder {
		opacity: 1.0;
	}

	.formerize-placeholder {
		opacity: 1.0;
	}

	label {
		color: #5e5e5e;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select,
	textarea {
		background-color: rgba(144, 144, 144, 0.075);
		border-color: #e3e3e3;
	}

		input[type="text"]:focus,
		input[type="password"]:focus,
		input[type="email"]:focus,
		select:focus,
		textarea:focus {
			border-color: #e09476;
			box-shadow: 0 0 0 1px #e09476;
		}

	select {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='%23e3e3e3' /%3E%3C/svg%3E");
	}

		select option {
			color: #5e5e5e;
			background: #ffffff;
		}

	input[type="checkbox"] + label,
	input[type="radio"] + label {
		color: #6e6e6e;
	}

		input[type="checkbox"] + label:before,
		input[type="radio"] + label:before {
			background: rgba(144, 144, 144, 0.075);
			border-color: #e3e3e3;
		}

	input[type="checkbox"]:checked + label:before,
	input[type="radio"]:checked + label:before {
		background-color: #e09476;
		border-color: #e09476;
		color: #ffffff;
	}

	input[type="checkbox"]:focus + label:before,
	input[type="radio"]:focus + label:before {
		border-color: #e09476;
		box-shadow: 0 0 0 1px #e09476;
	}

	::-webkit-input-placeholder {
		color: #cdcdcd !important;
	}

	:-moz-placeholder {
		color: #cdcdcd !important;
	}

	::-moz-placeholder {
		color: #cdcdcd !important;
	}

	:-ms-input-placeholder {
		color: #cdcdcd !important;
	}

	.formerize-placeholder {
		color: #cdcdcd !important;
	}

/* Icon */

	.icon {
		text-decoration: none;
		border-bottom: none;
		position: relative;
	}

		.icon:before {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1;
			text-transform: none !important;
			font-family: 'Font Awesome 5 Free';
			font-weight: 400;
		}

		.icon > .label {
			display: none;
		}

		.icon.solid:before {
			font-weight: 900;
		}

		.icon.brands:before {
			font-family: 'Font Awesome 5 Brands';
		}

		.icon.major {
			display: block;
			margin: 0 0 0.5em 0;
		}

			.icon.major:before {
				font-size: 3em;
				margin-bottom: 0.25em;
			}

			@media screen and (max-width: 736px) {

				.icon.major {
					margin: 0 0 1.5em 0;
				}

					.icon.major:before {
						font-size: 2em;
						font-size: 30px;
					}

			}

	.icon.major:before {
		color: #cdcdcd;
	}

/* Image */

	.image {
		border-radius: 4px;
		border: 0;
		display: inline-block;
		position: relative;
	}

		.image img {
			display: block;
			width: 100%;
		}

		.image.left, .image.right {
			max-width: 40%;
		}

			.image.left img, .image.right img {
				width: 100%;
			}

		.image.left {
			float: left;
			margin: 0 1.75em 1.25em 0;
			top: 0.25em;
		}

		.image.right {
			float: right;
			margin: 0 0 1.25em 1.75em;
			top: 0.25em;
		}

		.image.fit {
			display: block;
			margin: 0 0 2em 0;
			width: 100%;
		}

			.image.fit img {
				width: 100%;
			}

		.image.main {
			display: block;
			margin: 0 0 3em 0;
			width: 100%;
		}

			.image.main img {
				width: 100%;
			}

			@media screen and (max-width: 736px) {

				.image.main {
					margin: 0 0 2em 0;
				}

			}

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 2em 0;
		padding-left: 1.25em;
	}

		ol li {
			padding-left: 0.25em;
		}

	ul {
		list-style: disc;
		margin: 0 0 2em 0;
		padding-left: 1em;
	}

		ul li {
			padding-left: 0.5em;
		}

		ul.alt {
			list-style: none;
			padding-left: 0;
		}

			ul.alt li {
				border-top: solid 1px;
				padding: 0.5em 0;
			}

				ul.alt li:first-child {
					border-top: 0;
					padding-top: 0;
				}

	dl {
		margin: 0 0 2em 0;
	}

		dl dt {
			display: block;
			font-weight: 300;
			margin: 0 0 1em 0;
		}

		dl dd {
			margin-left: 2em;
		}

	ul.alt li {
		border-top-color: #e3e3e3;
	}

/* Actions */

	ul.actions {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		cursor: default;
		list-style: none;
		margin-left: -1em;
		padding-left: 0;
	}

		ul.actions li {
			padding: 0 0 0 1em;
			vertical-align: middle;
		}

		ul.actions.special {
			-moz-justify-content: center;
			-webkit-justify-content: center;
			-ms-justify-content: center;
			justify-content: center;
			width: 100%;
			margin-left: 0;
		}

			ul.actions.special li:first-child {
				padding-left: 0;
			}

		ul.actions.stacked {
			-moz-flex-direction: column;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			margin-left: 0;
		}

			ul.actions.stacked li {
				padding: 1.3em 0 0 0;
			}

				ul.actions.stacked li:first-child {
					padding-top: 0;
				}

		ul.actions.fit {
			width: calc(100% + 1em);
		}

			ul.actions.fit li {
				-moz-flex-grow: 1;
				-webkit-flex-grow: 1;
				-ms-flex-grow: 1;
				flex-grow: 1;
				-moz-flex-shrink: 1;
				-webkit-flex-shrink: 1;
				-ms-flex-shrink: 1;
				flex-shrink: 1;
				width: 100%;
			}

				ul.actions.fit li > * {
					width: 100%;
				}

			ul.actions.fit.stacked {
				width: 100%;
			}

		@media screen and (max-width: 480px) {

			ul.actions:not(.fixed) {
				-moz-flex-direction: column;
				-webkit-flex-direction: column;
				-ms-flex-direction: column;
				flex-direction: column;
				margin-left: 0;
				width: 100% !important;
			}

				ul.actions:not(.fixed) li {
					-moz-flex-grow: 1;
					-webkit-flex-grow: 1;
					-ms-flex-grow: 1;
					flex-grow: 1;
					-moz-flex-shrink: 1;
					-webkit-flex-shrink: 1;
					-ms-flex-shrink: 1;
					flex-shrink: 1;
					padding: 1em 0 0 0;
					text-align: center;
					width: 100%;
				}

					ul.actions:not(.fixed) li > * {
						width: 100%;
					}

					ul.actions:not(.fixed) li:first-child {
						padding-top: 0;
					}

					ul.actions:not(.fixed) li input[type="submit"],
					ul.actions:not(.fixed) li input[type="reset"],
					ul.actions:not(.fixed) li input[type="button"],
					ul.actions:not(.fixed) li button,
					ul.actions:not(.fixed) li .button {
						width: 100%;
					}

						ul.actions:not(.fixed) li input[type="submit"].icon:before,
						ul.actions:not(.fixed) li input[type="reset"].icon:before,
						ul.actions:not(.fixed) li input[type="button"].icon:before,
						ul.actions:not(.fixed) li button.icon:before,
						ul.actions:not(.fixed) li .button.icon:before {
							margin-left: -0.5em;
						}

		}

/* Icons */

	ul.icons {
		cursor: default;
		list-style: none;
		padding-left: 0;
	}

		ul.icons li {
			display: inline-block;
			padding: 0 1em 0 0;
		}

			ul.icons li:last-child {
				padding-right: 0;
			}

			ul.icons li .icon:before {
				font-size: 1em;
			}

/* Joined Icons */

	ul.joined-icons {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-justify-content: center;
		-webkit-justify-content: center;
		-ms-justify-content: center;
		justify-content: center;
		list-style: none;
		padding: 0;
	}

		ul.joined-icons li {
			border-style: solid;
			border-width: 1px;
			border-left-width: 0;
			padding: 0;
		}

			ul.joined-icons li a {
				display: block;
				width: 2.5em;
				height: 2.5em;
				line-height: 2.5em;
			}

			ul.joined-icons li:first-child {
				border-left-width: 1px;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}

			ul.joined-icons li:last-child {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}

	ul.joined-icons li {
		border-color: #e3e3e3;
	}

		ul.joined-icons li a {
			color: #cdcdcd;
		}

/* Major Icons */

	ul.major-icons {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-justify-content: center;
		-webkit-justify-content: center;
		-ms-justify-content: center;
		justify-content: center;
		list-style: none;
		margin: 3em 0;
		padding: 0;
	}

		ul.major-icons:last-child {
			margin-bottom: 2em;
		}

		ul.major-icons li {
			display: inline-block;
			border-style: solid;
			border-left-width: 1px;
			min-width: 15em;
			padding: 0;
		}

			ul.major-icons li:first-child {
				border-left: 0;
				margin-left: 0;
				padding-left: 0;
			}

			ul.major-icons li h3 {
				font-size: 0.8em;
			}

			ul.major-icons li > :last-child {
				margin-bottom: 0;
			}

		@media screen and (max-width: 736px) {

			ul.major-icons {
				-moz-flex-direction: column;
				-webkit-flex-direction: column;
				-ms-flex-direction: column;
				flex-direction: column;
			}

				ul.major-icons li {
					min-width: 0;
					width: 100%;
					border-left-width: 0;
					border-top-width: 1px;
					margin: 2em 0 0 0;
					padding: 2em 0 0 0;
				}

					ul.major-icons li:first-child {
						border-top: 0;
						margin-top: 0;
						padding-top: 0;
					}

					ul.major-icons li h3 {
						font-size: 1em;
					}

		}

	ul.major-icons li {
		border-color: #e3e3e3;
	}

/* Grid Icons */

	ul.grid-icons {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: solid;
		border-width: 1px;
		border-left-width: 0;
		list-style: none;
		padding: 0;
	}

		ul.grid-icons li {
			-moz-align-items: center;
			-webkit-align-items: center;
			-ms-align-items: center;
			align-items: center;
			display: -moz-flex;
			display: -webkit-flex;
			display: -ms-flex;
			display: flex;
			border-style: solid;
			border-width: 0;
			border-left-width: 1px;
			border-top-width: 1px;
			min-height: 13em;
			padding: 0;
			text-align: center;
			width: 50%;
		}

			ul.grid-icons li:nth-child(1), ul.grid-icons li:nth-child(2) {
				border-top-width: 0;
			}

			ul.grid-icons li:nth-child(2n - 1) {
				border-left-width: 0;
			}

			ul.grid-icons li > .inner {
				width: 100%;
			}

				ul.grid-icons li > .inner .icon {
					margin: 0;
				}

				ul.grid-icons li > .inner h3 {
					font-size: 1em;
					font-weight: 600;
					margin: 0;
				}

		@media screen and (max-width: 736px) {

			ul.grid-icons li {
				min-height: 11em;
			}

		}

	ul.grid-icons {
		border-color: #e3e3e3;
	}

		ul.grid-icons li {
			border-color: #e3e3e3;
		}

/* Faces */

	ul.faces {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		list-style: none;
		margin: 3.5em 0;
		padding: 0;
	}

		ul.faces:last-child {
			margin-bottom: 2em;
		}

		ul.faces li {
			-moz-flex: 1;
			-webkit-flex: 1;
			-ms-flex: 1;
			flex: 1;
			border-style: solid;
			border-left-width: 1px;
			margin-left: 3em;
			padding: 0;
			padding-left: 3em;
		}

			ul.faces li:first-child {
				border-left: 0;
				margin-left: 0;
				padding-left: 0;
			}

			ul.faces li h3 {
				font-size: 1em;
				font-weight: 600;
			}

			ul.faces li .image {
				margin: 0 0 2em 0;
			}

				ul.faces li .image img {
					border-radius: 100%;
				}

			ul.faces li p {
				font-style: italic;
			}

			ul.faces li > :last-child {
				margin-bottom: 0;
			}

		@media screen and (max-width: 980px) {

			ul.faces {
				-moz-flex-direction: column;
				-webkit-flex-direction: column;
				-ms-flex-direction: column;
				flex-direction: column;
			}

				ul.faces li {
					border-left-width: 0;
					border-top-width: 1px;
					margin-left: 0;
					padding-left: 0;
					margin-top: 3em;
					padding-top: 3em;
					-ms-flex: 0 1 auto;
				}

					ul.faces li:first-child {
						border-top-width: 0;
						margin-top: 0;
						padding-top: 0;
					}

		}

	ul.faces li {
		border-color: #e3e3e3;
	}

/* Logo */

	.logo {
		text-decoration: none;
		border: 0;
		color: inherit;
		display: block;
		margin: 0 0 1.5em 0;
	}

		.logo:before {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1;
			text-transform: none !important;
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
		}

		.logo:before {
			background: #e09476;
			border-radius: 4px;
			color: #ffffff;
			content: '\f0e7';
			display: inline-block;
			font-size: 3em;
			height: 1.65em;
			line-height: 1.65em;
			text-align: center;
			width: 1.65em;
		}

		@media screen and (max-width: 1280px) {

			.logo:before {
				font-size: 2.5em;
				font-size: 40px;
			}

		}

		@media screen and (max-width: 736px) {

			.logo {
				margin: 0 0 1em 0;
			}

				.logo:before {
					font-size: 2em;
					font-size: 30px;
				}

		}

/* Section/Article */

	section.special, article.special {
		text-align: center;
	}

	header p {
		position: relative;
		margin: 0 0 1.5em 0;
	}

	header h2 + p {
		font-size: 1.25em;
		margin-top: -1em;
	}

	header h3 + p {
		font-size: 1.1em;
		margin-top: -0.8em;
	}

	header h4 + p,
	header h5 + p,
	header h6 + p {
		font-size: 0.9em;
		margin-top: -0.6em;
	}

	header p {
		color: #cdcdcd;
	}

/* Table */

	.table-wrapper {
		-webkit-overflow-scrolling: touch;
		overflow-x: auto;
	}

	table {
		margin: 0 0 2em 0;
		width: 100%;
	}

		table.red {
			border: 1em solid #b01220;
		}

		table.green {
			border: 1em solid #68b530;
		}

		table.orange {
			border: 1em solid #f57215;
		}

		table.blue {
			border: 1em solid #162d66;
		}

		table tbody tr {
			border: solid 1px;
			border-left: 0;
			border-right: 0;
		}

		table td {
			padding: 0.75em 0.75em;
		}

		table th {
			font-size: 0.9em;
			font-weight: 300;
			padding: 0 0.75em 0.75em 0.75em;
			text-align: left;
		}

		table.red th {
			background-color: #b01220;
		}

		table.green th {
			background-color: #68b530;
		}

		table.orange th {
			background-color: #f57215;
		}

		table.blue th {
			background-color: #162d66;
		}

		table thead {
			border-bottom: solid 2px;
		}

		table tfoot {
			border-top: solid 2px;
		}

		table.alt {
			border-collapse: separate;
		}

			table.alt tbody tr td {
				border: solid 1px;
				border-left-width: 0;
				border-top-width: 0;
			}

				table.alt tbody tr td:first-child {
					border-left-width: 1px;
				}

			table.alt tbody tr:first-child td {
				border-top-width: 1px;
			}

			table.alt thead {
				border-bottom: 0;
			}

			table.alt tfoot {
				border-top: 0;
			}

	table tbody tr {
		border-color: #e3e3e3;
	}

		table tbody tr:nth-child(2n + 1) {
			background-color: rgba(144, 144, 144, 0.075);
		}

	table th {
		color: #ffffff;
	}

	th.price-column {
		width: 2em;
		text-align: center;
	}

	table thead {
		border-bottom-color: #e3e3e3;
	}

	table tfoot {
		border-top-color: #e3e3e3;
	}

	table.alt tbody tr td {
		border-color: #e3e3e3;
	}

	.menu-section-title {
		font-size: 1.7em;
		font-weight: bold;
	}

	.item-name {
		color: #407f1b;
		font-family: fantasy;
		font-size: 1.1em;
		font-style: italic;
		font-weight: bold;
		margin-right: 0.2em;
	}

	.price {
		color: #b01220;
	}

	tr.new td:nth-child(2) {
		position: relative;
	}
	
	tr.new td:nth-child(2):after {
		content: "NEW!";
		position: absolute;
		right: 5em;
		font-style: italic;
		font-size: 80%;
		font-weight: bold;
		color: black;
		background-color: gold;
		padding: 0.2em;
		border-radius: 0.5em;
	}

/* Banner */

	.banner {
		position: relative;
		text-align: center;
	}
		.banner img {
			width: 100%;
		}
		.strapline {
			font-variant: small-caps;
			font-size: 200%;
			margin-top: -0.5em;
			letter-spacing: 0.2em;
		}

		.banner input[type="submit"],
		.banner input[type="reset"],
		.banner input[type="button"],
		.banner button,
		.banner .button {
			background-color: transparent;
			box-shadow: inset 0 0 0 1px #ffffff;
			color: #ffffff !important;
		}

			.banner input[type="submit"]:hover,
			.banner input[type="reset"]:hover,
			.banner input[type="button"]:hover,
			.banner button:hover,
			.banner .button:hover {
				background-color: rgba(255, 255, 255, 0.075);
				color: #ffffff !important;
			}

			.banner input[type="submit"]:active,
			.banner input[type="reset"]:active,
			.banner input[type="button"]:active,
			.banner button:active,
			.banner .button:active {
				background-color: rgba(255, 255, 255, 0.2);
			}

			.banner input[type="submit"].primary,
			.banner input[type="reset"].primary,
			.banner input[type="button"].primary,
			.banner button.primary,
			.banner .button.primary {
				box-shadow: none;
				background-color: #ffffff;
				color: #4a4a4a !important;
			}

		.banner input, .banner select, .banner textarea {
			color: #ffffff;
		}

		.banner a:hover {
			color: #ffffff !important;
		}

		.banner strong, .banner b {
			color: #000000;
		}

		.banner h1, .banner h2, .banner h3, .banner h4, .banner h5, .banner h6 {
			color: #000000;
		}

		.banner blockquote {
			border-left-color: #ffffff;
		}

		.banner code {
			background: rgba(255, 255, 255, 0.075);
			border-color: #ffffff;
		}

		.banner hr {
			border-bottom-color: #ffffff;
		}

		.banner h2 {
			font-size: 2.5em;
			margin: 0 0 1em 0;
		}

		.banner > * {
			-moz-transition: opacity 2s ease-in-out;
			-webkit-transition: opacity 2s ease-in-out;
			-ms-transition: opacity 2s ease-in-out;
			transition: opacity 2s ease-in-out;
			-moz-transition-delay: 1.25s;
			-webkit-transition-delay: 1.25s;
			-ms-transition-delay: 1.25s;
			transition-delay: 1.25s;
			opacity: 1;
		}

		@media screen and (max-width: 1280px) {

			.banner h2 {
				font-size: 2em;
			}

		}


		@media screen and (max-width: 736px) {

			.strapline {
				font-variant: small-caps;
				font-size: 70%;
				margin-top: -0.5em;
			}
	
			.banner h2 {
				font-size: 1.75em;
			}

		}

		@media screen and (max-width: 360px) {

			.banner h2 {
				font-size: 1.5em;
			}

		}

		body.is-preload .banner > * {
			opacity: 0;
		}

/* Wrapper */

	.wrapper {
		padding: 5em 5em 3em 5em ;
		position: relative;
	}
		
		.wrapper.style1 {
			background-color: #ffffff;
			color: #030303;
			border-top: 10px solid #b01220;
			border-bottom: 10px solid #b01220;
		}

			.wrapper.style1 hr {
				border-bottom-color: #ffffff;
			}

			.wrapper.style1 .box {
				border-color: #ffffff;
			}

			.wrapper.style1 input[type="submit"],
			.wrapper.style1 input[type="reset"],
			.wrapper.style1 input[type="button"],
			.wrapper.style1 button,
			.wrapper.style1 .button {
				background-color: transparent;
				box-shadow: inset 0 0 0 1px #ffffff;
				color: #ffffff !important;
			}

				.wrapper.style1 input[type="submit"]:hover,
				.wrapper.style1 input[type="reset"]:hover,
				.wrapper.style1 input[type="button"]:hover,
				.wrapper.style1 button:hover,
				.wrapper.style1 .button:hover {
					background-color: rgba(255, 255, 255, 0.075);
					color: #ffffff !important;
				}

				.wrapper.style1 input[type="submit"]:active,
				.wrapper.style1 input[type="reset"]:active,
				.wrapper.style1 input[type="button"]:active,
				.wrapper.style1 button:active,
				.wrapper.style1 .button:active {
					background-color: rgba(255, 255, 255, 0.2);
				}

				.wrapper.style1 input[type="submit"].primary,
				.wrapper.style1 input[type="reset"].primary,
				.wrapper.style1 input[type="button"].primary,
				.wrapper.style1 button.primary,
				.wrapper.style1 .button.primary {
					box-shadow: none;
					background-color: #ffffff;
					color: #e09476 !important;
				}

			.wrapper.style1 label {
				color: #ffffff;
			}

			.wrapper.style1 input[type="text"],
			.wrapper.style1 input[type="password"],
			.wrapper.style1 input[type="email"],
			.wrapper.style1 select,
			.wrapper.style1 textarea {
				background-color: rgba(255, 255, 255, 0.075);
				border-color: #ffffff;
			}

				.wrapper.style1 input[type="text"]:focus,
				.wrapper.style1 input[type="password"]:focus,
				.wrapper.style1 input[type="email"]:focus,
				.wrapper.style1 select:focus,
				.wrapper.style1 textarea:focus {
					border-color: #ffffff;
					box-shadow: 0 0 0 1px #ffffff;
				}

			.wrapper.style1 select {
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='%23ffffff' /%3E%3C/svg%3E");
			}

				.wrapper.style1 select option {
					color: #ffffff;
					background: #e09476;
				}

			.wrapper.style1 input[type="checkbox"] + label,
			.wrapper.style1 input[type="radio"] + label {
				color: #ffffff;
			}

				.wrapper.style1 input[type="checkbox"] + label:before,
				.wrapper.style1 input[type="radio"] + label:before {
					background: rgba(255, 255, 255, 0.075);
					border-color: #ffffff;
				}

			.wrapper.style1 input[type="checkbox"]:checked + label:before,
			.wrapper.style1 input[type="radio"]:checked + label:before {
				background-color: #ffffff;
				border-color: #ffffff;
				color: #e09476;
			}

			.wrapper.style1 input[type="checkbox"]:focus + label:before,
			.wrapper.style1 input[type="radio"]:focus + label:before {
				border-color: #ffffff;
				box-shadow: 0 0 0 1px #ffffff;
			}

			.wrapper.style1 ::-webkit-input-placeholder {
				color: #ffffff !important;
			}

			.wrapper.style1 :-moz-placeholder {
				color: #ffffff !important;
			}

			.wrapper.style1 ::-moz-placeholder {
				color: #ffffff !important;
			}

			.wrapper.style1 :-ms-input-placeholder {
				color: #ffffff !important;
			}

			.wrapper.style1 .formerize-placeholder {
				color: #ffffff !important;
			}

			.wrapper.style1 .icon.major:before {
				color: #ffffff;
			}

			.wrapper.style1 ul.alt li {
				border-top-color: #ffffff;
			}

			.wrapper.style1 ul.joined-icons li {
				border-color: #ffffff;
			}

				.wrapper.style1 ul.joined-icons li a {
					color: #ffffff;
				}

			.wrapper.style1 ul.major-icons li {
				border-color: #ffffff;
			}

			.wrapper.style1 ul.grid-icons {
				border-color: #ffffff;
			}

				.wrapper.style1 ul.grid-icons li {
					border-color: #ffffff;
				}

			.wrapper.style1 ul.faces li {
				border-color: #ffffff;
			}

			.wrapper.style1 header p {
				color: #ffffff;
			}

			.wrapper.style1 table tbody tr {
				border-color: #ffffff;
			}

				.wrapper.style1 table tbody tr:nth-child(2n + 1) {
					background-color: rgba(255, 255, 255, 0.075);
				}

			.wrapper.style1 table th {
				color: #ffffff;
			}

			.wrapper.style1 table thead {
				border-bottom-color: #ffffff;
			}

			.wrapper.style1 table tfoot {
				border-top-color: #ffffff;
			}

			.wrapper.style1 table.alt tbody tr td {
				border-color: #ffffff;
			}

		.wrapper.style2 {
			background-color: #b57168;
			color: #ffffff;
		}

			.wrapper.style2 input, .wrapper.style2 select, .wrapper.style2 textarea {
				color: #ffffff;
			}

			.wrapper.style2 a:hover {
				color: #ffffff !important;
			}

			.wrapper.style2 strong, .wrapper.style2 b {
				color: #ffffff;
			}

			.wrapper.style2 h1, .wrapper.style2 h2, .wrapper.style2 h3, .wrapper.style2 h4, .wrapper.style2 h5, .wrapper.style2 h6 {
				color: #ffffff;
			}

			.wrapper.style2 blockquote {
				border-left-color: #ffffff;
			}

			.wrapper.style2 code {
				background: rgba(255, 255, 255, 0.075);
				border-color: #ffffff;
			}

			.wrapper.style2 hr {
				border-bottom-color: #ffffff;
			}

			.wrapper.style2 .box {
				border-color: #ffffff;
			}

			.wrapper.style2 input[type="submit"],
			.wrapper.style2 input[type="reset"],
			.wrapper.style2 input[type="button"],
			.wrapper.style2 button,
			.wrapper.style2 .button {
				background-color: transparent;
				box-shadow: inset 0 0 0 1px #ffffff;
				color: #ffffff !important;
			}

				.wrapper.style2 input[type="submit"]:hover,
				.wrapper.style2 input[type="reset"]:hover,
				.wrapper.style2 input[type="button"]:hover,
				.wrapper.style2 button:hover,
				.wrapper.style2 .button:hover {
					background-color: rgba(255, 255, 255, 0.075);
					color: #ffffff !important;
				}

				.wrapper.style2 input[type="submit"]:active,
				.wrapper.style2 input[type="reset"]:active,
				.wrapper.style2 input[type="button"]:active,
				.wrapper.style2 button:active,
				.wrapper.style2 .button:active {
					background-color: rgba(255, 255, 255, 0.2);
				}

				.wrapper.style2 input[type="submit"].primary,
				.wrapper.style2 input[type="reset"].primary,
				.wrapper.style2 input[type="button"].primary,
				.wrapper.style2 button.primary,
				.wrapper.style2 .button.primary {
					box-shadow: none;
					background-color: #ffffff;
					color: #b57168 !important;
				}

			.wrapper.style2 label {
				color: #ffffff;
			}

			.wrapper.style2 input[type="text"],
			.wrapper.style2 input[type="password"],
			.wrapper.style2 input[type="email"],
			.wrapper.style2 select,
			.wrapper.style2 textarea {
				background-color: rgba(255, 255, 255, 0.075);
				border-color: #ffffff;
			}

				.wrapper.style2 input[type="text"]:focus,
				.wrapper.style2 input[type="password"]:focus,
				.wrapper.style2 input[type="email"]:focus,
				.wrapper.style2 select:focus,
				.wrapper.style2 textarea:focus {
					border-color: #ffffff;
					box-shadow: 0 0 0 1px #ffffff;
				}

			.wrapper.style2 select {
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='%23ffffff' /%3E%3C/svg%3E");
			}

				.wrapper.style2 select option {
					color: #ffffff;
					background: #b57168;
				}

			.wrapper.style2 input[type="checkbox"] + label,
			.wrapper.style2 input[type="radio"] + label {
				color: #ffffff;
			}

				.wrapper.style2 input[type="checkbox"] + label:before,
				.wrapper.style2 input[type="radio"] + label:before {
					background: rgba(255, 255, 255, 0.075);
					border-color: #ffffff;
				}

			.wrapper.style2 input[type="checkbox"]:checked + label:before,
			.wrapper.style2 input[type="radio"]:checked + label:before {
				background-color: #ffffff;
				border-color: #ffffff;
				color: #b57168;
			}

			.wrapper.style2 input[type="checkbox"]:focus + label:before,
			.wrapper.style2 input[type="radio"]:focus + label:before {
				border-color: #ffffff;
				box-shadow: 0 0 0 1px #ffffff;
			}

			.wrapper.style2 ::-webkit-input-placeholder {
				color: #ffffff !important;
			}

			.wrapper.style2 :-moz-placeholder {
				color: #ffffff !important;
			}

			.wrapper.style2 ::-moz-placeholder {
				color: #ffffff !important;
			}

			.wrapper.style2 :-ms-input-placeholder {
				color: #ffffff !important;
			}

			.wrapper.style2 .formerize-placeholder {
				color: #ffffff !important;
			}

			.wrapper.style2 .icon.major:before {
				color: #ffffff;
			}

			.wrapper.style2 ul.alt li {
				border-top-color: #ffffff;
			}

			.wrapper.style2 ul.joined-icons li {
				border-color: #ffffff;
			}

				.wrapper.style2 ul.joined-icons li a {
					color: #ffffff;
				}

			.wrapper.style2 ul.major-icons li {
				border-color: #ffffff;
			}

			.wrapper.style2 ul.grid-icons {
				border-color: #ffffff;
			}

				.wrapper.style2 ul.grid-icons li {
					border-color: #ffffff;
				}

			.wrapper.style2 ul.faces li {
				border-color: #ffffff;
			}

			.wrapper.style2 header p {
				color: #ffffff;
			}

			.wrapper.style2 table tbody tr {
				border-color: #ffffff;
			}

				.wrapper.style2 table tbody tr:nth-child(2n + 1) {
					background-color: rgba(255, 255, 255, 0.075);
				}

			.wrapper.style2 table th {
				color: #ffffff;
			}

			.wrapper.style2 table thead {
				border-bottom-color: #ffffff;
			}

			.wrapper.style2 table tfoot {
				border-top-color: #ffffff;
			}

			.wrapper.style2 table.alt tbody tr td {
				border-color: #ffffff;
			}

		.wrapper.style3 {
			background-color: #89bdc0;
			color: #ffffff;
		}

			.wrapper.style3 input, .wrapper.style3 select, .wrapper.style3 textarea {
				color: #ffffff;
			}

			.wrapper.style3 a:hover {
				color: #ffffff !important;
			}

			.wrapper.style3 strong, .wrapper.style3 b {
				color: #ffffff;
			}

			.wrapper.style3 h1, .wrapper.style3 h2, .wrapper.style3 h3, .wrapper.style3 h4, .wrapper.style3 h5, .wrapper.style3 h6 {
				color: #ffffff;
			}

			.wrapper.style3 blockquote {
				border-left-color: #ffffff;
			}

			.wrapper.style3 code {
				background: rgba(255, 255, 255, 0.075);
				border-color: #ffffff;
			}

			.wrapper.style3 hr {
				border-bottom-color: #ffffff;
			}

			.wrapper.style3 .box {
				border-color: #ffffff;
			}

			.wrapper.style3 input[type="submit"],
			.wrapper.style3 input[type="reset"],
			.wrapper.style3 input[type="button"],
			.wrapper.style3 button,
			.wrapper.style3 .button {
				background-color: transparent;
				box-shadow: inset 0 0 0 1px #ffffff;
				color: #ffffff !important;
			}

				.wrapper.style3 input[type="submit"]:hover,
				.wrapper.style3 input[type="reset"]:hover,
				.wrapper.style3 input[type="button"]:hover,
				.wrapper.style3 button:hover,
				.wrapper.style3 .button:hover {
					background-color: rgba(255, 255, 255, 0.075);
					color: #ffffff !important;
				}

				.wrapper.style3 input[type="submit"]:active,
				.wrapper.style3 input[type="reset"]:active,
				.wrapper.style3 input[type="button"]:active,
				.wrapper.style3 button:active,
				.wrapper.style3 .button:active {
					background-color: rgba(255, 255, 255, 0.2);
				}

				.wrapper.style3 input[type="submit"].primary,
				.wrapper.style3 input[type="reset"].primary,
				.wrapper.style3 input[type="button"].primary,
				.wrapper.style3 button.primary,
				.wrapper.style3 .button.primary {
					box-shadow: none;
					background-color: #ffffff;
					color: #89bdc0 !important;
				}

			.wrapper.style3 label {
				color: #ffffff;
			}

			.wrapper.style3 input[type="text"],
			.wrapper.style3 input[type="password"],
			.wrapper.style3 input[type="email"],
			.wrapper.style3 select,
			.wrapper.style3 textarea {
				background-color: rgba(255, 255, 255, 0.075);
				border-color: #ffffff;
			}

				.wrapper.style3 input[type="text"]:focus,
				.wrapper.style3 input[type="password"]:focus,
				.wrapper.style3 input[type="email"]:focus,
				.wrapper.style3 select:focus,
				.wrapper.style3 textarea:focus {
					border-color: #ffffff;
					box-shadow: 0 0 0 1px #ffffff;
				}

			.wrapper.style3 select {
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='%23ffffff' /%3E%3C/svg%3E");
			}

				.wrapper.style3 select option {
					color: #ffffff;
					background: #89bdc0;
				}

			.wrapper.style3 input[type="checkbox"] + label,
			.wrapper.style3 input[type="radio"] + label {
				color: #ffffff;
			}

				.wrapper.style3 input[type="checkbox"] + label:before,
				.wrapper.style3 input[type="radio"] + label:before {
					background: rgba(255, 255, 255, 0.075);
					border-color: #ffffff;
				}

			.wrapper.style3 input[type="checkbox"]:checked + label:before,
			.wrapper.style3 input[type="radio"]:checked + label:before {
				background-color: #ffffff;
				border-color: #ffffff;
				color: #89bdc0;
			}

			.wrapper.style3 input[type="checkbox"]:focus + label:before,
			.wrapper.style3 input[type="radio"]:focus + label:before {
				border-color: #ffffff;
				box-shadow: 0 0 0 1px #ffffff;
			}

			.wrapper.style3 ::-webkit-input-placeholder {
				color: #ffffff !important;
			}

			.wrapper.style3 :-moz-placeholder {
				color: #ffffff !important;
			}

			.wrapper.style3 ::-moz-placeholder {
				color: #ffffff !important;
			}

			.wrapper.style3 :-ms-input-placeholder {
				color: #ffffff !important;
			}

			.wrapper.style3 .formerize-placeholder {
				color: #ffffff !important;
			}

			.wrapper.style3 .icon.major:before {
				color: #ffffff;
			}

			.wrapper.style3 ul.alt li {
				border-top-color: #ffffff;
			}

			.wrapper.style3 ul.joined-icons li {
				border-color: #ffffff;
			}

				.wrapper.style3 ul.joined-icons li a {
					color: #ffffff;
				}

			.wrapper.style3 ul.major-icons li {
				border-color: #ffffff;
			}

			.wrapper.style3 ul.grid-icons {
				border-color: #ffffff;
			}

				.wrapper.style3 ul.grid-icons li {
					border-color: #ffffff;
				}

			.wrapper.style3 ul.faces li {
				border-color: #ffffff;
			}

			.wrapper.style3 header p {
				color: #ffffff;
			}

			.wrapper.style3 table tbody tr {
				border-color: #ffffff;
			}

				.wrapper.style3 table tbody tr:nth-child(2n + 1) {
					background-color: rgba(255, 255, 255, 0.075);
				}

			.wrapper.style3 table th {
				color: #ffffff;
			}

			.wrapper.style3 table thead {
				border-bottom-color: #ffffff;
			}

			.wrapper.style3 table tfoot {
				border-top-color: #ffffff;
			}

			.wrapper.style3 table.alt tbody tr td {
				border-color: #ffffff;
			}

		.wrapper.spotlight {
			display: -moz-flex;
			display: -webkit-flex;
			display: -ms-flex;
			display: flex;
			-moz-align-items: -moz-stretch;
			-webkit-align-items: -webkit-stretch;
			-ms-align-items: -ms-stretch;
			align-items: stretch;
			padding: 0;
		}

			.wrapper.spotlight > * {
				margin: 0;
			}

				.wrapper.spotlight > * > * {
					-ms-flex: 0 1 auto;
				}

			.wrapper.spotlight > :first-child {
				width: 60%;
			}

			.wrapper.spotlight > :last-child {
				width: 40%;
			}

			.wrapper.spotlight > .content {
				display: -moz-flex;
				display: -webkit-flex;
				display: -ms-flex;
				display: flex;
				-moz-align-items: center;
				-webkit-align-items: center;
				-ms-align-items: center;
				align-items: center;
				padding: 0.5em 1em 0em 3em;
			}

			.wrapper.spotlight > .content > .inner > h2 {
				margin-bottom: 0.2em;
			}

			.wrapper.spotlight > .content > .inner > h3 {
				color: #b01220;
				margin-bottom: 0.2em;
				margin-left: 4em;
			}

			.phone::after {
				content: "\f095";
				height: 1em;
				width: 1em;
				line-height: 1;
				text-transform: none !important;
				font-family: 'Font Awesome 5 Free';
				font-size: 81%;
				margin-left: 0.2em;
			}

			.postcode {
				font-weight: bold;
				position: relative;
			}

			.postcode:after {
				content: '';
				position: absolute;
				margin-left: 0.3em;
				margin-top: 0.1em;
				background-image: url(/images/google-maps-icon.png);
				width: 1em;
				height: 1.5em;
				background-position: center center;
				background-size: contain;
				background-repeat: no-repeat;
			}

			.wrapper.spotlight > .content > .inner > ul {
				margin: 0 0 0 6em;
				color: #4a9e0c;
			}

			.wrapper.spotlight > .grid-icons {
				border-width: 0px;
				border-left-width: 1px;
			}

			.wrapper.spotlight > .image {
				border-radius: 0;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				display: block;
			}

			.wrapper.spotlight.alt {
				-moz-flex-direction: row-reverse;
				-webkit-flex-direction: row-reverse;
				-ms-flex-direction: row-reverse;
				flex-direction: row-reverse;
			}

		@media screen and (max-width: 1280px) {

			.wrapper {
				padding: 1em 1em 1em 1em ;
			}

				.wrapper.spotlight > .content {
					padding: 1em 1em 1em 1em ;
				}

		}

		@media screen and (max-width: 980px) {

			.wrapper.spotlight {
				-moz-flex-direction: column;
				-webkit-flex-direction: column;
				-ms-flex-direction: column;
				flex-direction: column;
			}

				.wrapper.spotlight ul.actions {
					-moz-justify-content: center;
					-webkit-justify-content: center;
					-ms-justify-content: center;
					justify-content: center;
					width: 100%;
					margin-left: 0;
				}

					.wrapper.spotlight ul.actions li:first-child {
						padding-left: 0;
					}

				.wrapper.spotlight > :first-child {
					width: 100%;
				}

				.wrapper.spotlight > :last-child {
					width: 100%;
				}

				.wrapper.spotlight > .grid-icons {
					border-width: 0px;
					border-top-width: 1px;
				}

				.wrapper.spotlight > .content + .image {
					-moz-order: -1;
					-webkit-order: -1;
					-ms-order: -1;
					order: -1;
				}

				.wrapper.spotlight > .image {
					height: 22em;
					max-height: 50vh;
				}

				.wrapper.spotlight.alt {
					-moz-flex-direction: column;
					-webkit-flex-direction: column;
					-ms-flex-direction: column;
					flex-direction: column;
				}

		}

		@media screen and (max-width: 736px) {

			.wrapper {
				padding: 0 3em 1em 3em ;
			}

				.wrapper.spotlight > .content {
					padding: 0 3em 1em 3em ;
				}

		}

		@media screen and (max-width: 480px) {

			.wrapper {
				padding: 0 0 1em 0 ;
			}
				.wrapper.spotlight > .content {
					padding: 0 2em 1em 1em ;
				}

				table td {
					padding: 0.15em 0.15em;
				}

		}

/* Header */

	@-moz-keyframes reveal-header {
		0% {
			top: -4em;
			opacity: 0;
		}

		100% {
			top: 0;
			opacity: 1;
		}
	}

	@-webkit-keyframes reveal-header {
		0% {
			top: -4em;
			opacity: 0;
		}

		100% {
			top: 0;
			opacity: 1;
		}
	}

	@-ms-keyframes reveal-header {
		0% {
			top: -4em;
			opacity: 0;
		}

		100% {
			top: 0;
			opacity: 1;
		}
	}

	@keyframes reveal-header {
		0% {
			top: -4em;
			opacity: 0;
		}

		100% {
			top: 0;
			opacity: 1;
		}
	}

	#header {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		background: #ffffff;
		box-shadow: 0 0 0.15em 0 rgba(0, 0, 0, 0.075);
		height: 3.5em;
		left: 0;
		line-height: 3.5em;
		padding: 0 2em;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 10001;
	}

		#header > .logo {
			display: inline-block;
			margin: 0;
			white-space: nowrap;
		}

			#header > .logo:before {
				font-size: 1em;
				font-size: 16px;
				margin-left: -1em;
				margin-right: 0.75em;
			}

		#header > nav {
			width: 100%;
			text-align: right;
		}

			#header > nav > ul {
				list-style: none;
				padding: 0;
				margin: 0;
			}

				#header > nav > ul > li {
					display: inline-block;
					margin-left: 1em;
					padding: 0;
				}

					#header > nav > ul > li > ul {
						display: none;
					}

					#header > nav > ul > li a {
						color: inherit;
						border: 0;
						padding: 0.35em 0.75em;
						border-radius: 4px;
					}

					#header > nav > ul > li:first-child {
						margin-left: 0 !important;
					}

					#header > nav > ul > li.dropotron-active a {
						color: inherit !important;
						background-color: rgba(144, 144, 144, 0.075);
					}

					#header > nav > ul > li.active a {
						color: #5e5e5e;
						font-weight: 600;
						position: relative;
					}

						#header > nav > ul > li.active a:after {
							-moz-transition: background-color 0.2s ease-in-out;
							-webkit-transition: background-color 0.2s ease-in-out;
							-ms-transition: background-color 0.2s ease-in-out;
							transition: background-color 0.2s ease-in-out;
							background-color: #e3e3e3;
							border-radius: 0.175em;
							bottom: -0.05em;
							content: '';
							height: 0.175em;
							left: 0.5em;
							position: absolute;
							width: calc(100% - 1em);
						}

						#header > nav > ul > li.active a:hover:after {
							background-color: #e09476;
						}

		#header.reveal {
			-moz-animation: reveal-header 0.5s ease;
			-webkit-animation: reveal-header 0.5s ease;
			-ms-animation: reveal-header 0.5s ease;
			animation: reveal-header 0.5s ease;
		}

		#header.alt {
			-moz-align-items: center;
			-webkit-align-items: center;
			-ms-align-items: center;
			align-items: center;
			-moz-animation: none;
			-webkit-animation: none;
			-ms-animation: none;
			animation: none;
			background-color: transparent;
			box-shadow: none;
			height: 8em;
			position: absolute;
		}

			#header.alt > .logo {
				display: none;
			}

			#header.alt > nav {
				text-align: center;
			}

				#header.alt > nav > ul > li {
					margin-left: 1.5em;
				}

		@media screen and (max-width: 1280px) {

			#header > nav > ul > li {
				margin-left: 0.5em;
			}

			#header.alt > nav > ul > li {
				margin-left: 1em;
			}

		}

	.dropotron {
		background: #fff;
		border-radius: 4px;
		box-shadow: 0 0.05em 0.175em 0 rgba(0, 0, 0, 0.1);
		list-style: none;
		margin-top: -0.75em;
		min-width: 10em;
		padding: 0.75em 0;
	}

		.dropotron li {
			padding: 0;
		}

			.dropotron li a {
				-moz-transition: none !important;
				-webkit-transition: none !important;
				-ms-transition: none !important;
				transition: none !important;
				border: 0;
				box-shadow: inset 0 1px 0 0 rgba(144, 144, 144, 0.075);
				color: inherit;
				display: block;
				line-height: 2.25em;
				padding: 0 1em;
			}

			.dropotron li:first-child > a {
				box-shadow: none;
			}

			.dropotron li.active > a, .dropotron li:hover > a {
				background: #e09476;
				color: #ffffff !important;
			}

		.dropotron.level-0 {
			font-size: 0.9em;
			margin-top: 0.75em;
		}

			.dropotron.level-0:before {
				-moz-transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
				background: #fff;
				content: '';
				display: block;
				height: 0.75em;
				position: absolute;
				left: 50%;
				top: -0.375em;
				width: 0.75em;
				margin-left: -0.375em;
				box-shadow: inset 1px 1px 0 0 rgba(0, 0, 0, 0.075);
				box-shadow: -1px -1px 2px 0 rgba(0, 0, 0, 0.05);
			}

	@media screen and (max-width: 1280px) {

		body {
			padding-top: 6em;
		}

		#header.alt {
			height: 6em;
		}

	}

	@media screen and (max-width: 980px) {

		body {
			padding-top: 0;
		}

		#header {
			display: none;
		}

	}

/* Main */

	#main {
		background-color: #ffffff;
		border-radius: 1.5em;
		margin: 0 auto;
		width: 70em;
		max-width: calc(100% - 4em);
	}

		#main > header {
			padding: 4em 0 2em 0 ;
			text-align: center;
		}

			#main > header .logo {
				-moz-transition: -moz-transform 1s ease, opacity 1s ease-in-out;
				-webkit-transition: -webkit-transform 1s ease, opacity 1s ease-in-out;
				-ms-transition: -ms-transform 1s ease, opacity 1s ease-in-out;
				transition: transform 1s ease, opacity 1s ease-in-out;
				-moz-transform: translateY(0);
				-webkit-transform: translateY(0);
				-ms-transform: translateY(0);
				transform: translateY(0);
				opacity: 1;
			}

			#main > header .logo + h1 {
				-moz-transition: opacity 1s ease-in-out;
				-webkit-transition: opacity 1s ease-in-out;
				-ms-transition: opacity 1s ease-in-out;
				transition: opacity 1s ease-in-out;
				-moz-transition-delay: 0.35s;
				-webkit-transition-delay: 0.35s;
				-ms-transition-delay: 0.35s;
				transition-delay: 0.35s;
				font-size: 1.35em;
				opacity: 1;
			}

		#main > #content {
			padding-top: 2em;
		}

		@media screen and (max-width: 980px) {

			#main {
				border-radius: 0;
				max-width: 100%;
			}

				#main > header {
					padding: 5em 0 3em 0 ;
				}

		}

		@media screen and (max-width: 736px) {

			#main > header {
				padding: 4em 0 2em 0 ;
			}

		}

		body.is-preload #main > header .logo {
			-moz-transform: translateY(1em);
			-webkit-transform: translateY(1em);
			-ms-transform: translateY(1em);
			transform: translateY(1em);
			opacity: 0;
		}

		body.is-preload #main > header .logo + h1 {
			opacity: 0;
		}

/* Footer */

	#footer {
		padding: 5em 5em 3em 5em ;
		margin: 0 auto;
		text-align: center;
		width: 70em;
		max-width: calc(100% - 4em);
	}

		#footer .copyright {
			color: #cdcdcd;
			margin: 4em 0 2em 0;
		}

		@media screen and (max-width: 1280px) {

			#footer {
				padding: 4em 4em 2em 4em ;
			}

		}

		@media screen and (max-width: 980px) {

			#footer {
				max-width: 100%;
			}

		}

		@media screen and (max-width: 736px) {

			#footer {
				padding: 3em 3em 1em 3em ;
			}

		}

		@media screen and (max-width: 480px) {

			#footer {
				padding: 2em 2em 0.1em 2em ;
			}

		}

/* Navigation Panel */

	#navPanel {
		-moz-transform: translateX(-20em);
		-webkit-transform: translateX(-20em);
		-ms-transform: translateX(-20em);
		transform: translateX(-20em);
		-moz-transition: -moz-transform 0.5s ease, box-shadow 0.5s ease, visibility 0.5s;
		-webkit-transition: -webkit-transform 0.5s ease, box-shadow 0.5s ease, visibility 0.5s;
		-ms-transition: -ms-transform 0.5s ease, box-shadow 0.5s ease, visibility 0.5s;
		transition: transform 0.5s ease, box-shadow 0.5s ease, visibility 0.5s;
		-webkit-overflow-scrolling: touch;
		background-color: #ffffff;
		box-shadow: none;
		display: none;
		height: 100%;
		max-width: 80%;
		overflow-y: auto;
		position: fixed;
		left: 0;
		top: 0;
		visibility: hidden;
		width: 20em;
		z-index: 10002;
	}

		#navPanel nav {
			padding: 3em 2em;
		}

		#navPanel .link {
			border: 0;
			border-top: solid 1px #e3e3e3;
			color: inherit !important;
			display: block;
			font-size: 0.9em;
			padding: 1em 0;
		}

			#navPanel .link:first-child {
				border-top: 0;
			}

			#navPanel .link.depth-0 {
				font-weight: 600;
				color: #5e5e5e !important;
			}

			#navPanel .link .indent-1 {
				display: inline-block;
				width: 1.25em;
			}

			#navPanel .link .indent-2 {
				display: inline-block;
				width: 2.5em;
			}

			#navPanel .link .indent-3 {
				display: inline-block;
				width: 3.75em;
			}

			#navPanel .link .indent-4 {
				display: inline-block;
				width: 5em;
			}

			#navPanel .link .indent-5 {
				display: inline-block;
				width: 6.25em;
			}

		#navPanel .close {
			text-decoration: none;
			-moz-transition: color 0.2s ease-in-out;
			-webkit-transition: color 0.2s ease-in-out;
			-ms-transition: color 0.2s ease-in-out;
			transition: color 0.2s ease-in-out;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			border: 0;
			color: #cdcdcd;
			cursor: pointer;
			display: block;
			height: 4em;
			padding-right: 1.25em;
			position: absolute;
			right: 0;
			text-align: right;
			top: 0;
			vertical-align: middle;
			width: 5em;
		}

			#navPanel .close:before {
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				text-transform: none !important;
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
			}

			#navPanel .close:before {
				content: '\f00d';
				width: 3em;
				height: 3em;
				line-height: 3em;
				display: block;
				position: absolute;
				right: 0;
				top: 0;
				text-align: center;
			}

			#navPanel .close:hover {
				color: inherit;
			}

			@media screen and (max-width: 736px) {

				#navPanel .close {
					height: 4em;
					line-height: 4em;
				}

			}

		#navPanel.visible {
			-moz-transform: translateX(0);
			-webkit-transform: translateX(0);
			-ms-transform: translateX(0);
			transform: translateX(0);
			box-shadow: 0.075em 0 0.25em 0 rgba(0, 0, 0, 0.1);
			visibility: visible;
		}

		@media screen and (max-width: 980px) {

			#navPanel {
				display: block;
			}

		}

		@media screen and (max-width: 736px) {

			#navPanel {
				display: block;
			}

				#navPanel nav {
					padding: 2.25em 1.25em;
				}

		}

	#navButton {
		display: none;
		height: 4em;
		left: 0;
		position: fixed;
		top: 0;
		width: 6em;
		z-index: 10001;
	}

		#navButton .toggle {
			text-decoration: none;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			outline: 0;
			border: 0;
		}

			#navButton .toggle:before {
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				text-transform: none !important;
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
			}

			#navButton .toggle:before {
				background: rgba(103, 107, 113, 0.5);
				border-radius: 4px;
				color: #fff;
				content: '\f0c9';
				display: block;
				font-size: 16px;
				height: 2.25em;
				left: 0.5em;
				line-height: 2.25em;
				position: absolute;
				text-align: center;
				top: 0.5em;
				width: 3.5em;
			}

		@media screen and (max-width: 980px) {

			#navButton {
				display: block;
			}

		}
